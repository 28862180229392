import React from "react";
import { Link } from "react-router-dom";

const footerItems = [
  // { itemName: "Faqs", link: "/faq" },
  // { itemName: "Pricing", link: "/pricing" },
  // { itemName: "Coming Soon", link: "/coming-soon" },
  { itemName: "About", link: "/about-us" },
  { itemName: "Portfolio", link: "/portfolio" },
  { itemName: "Blog", link: "/blog" },
  { itemName: "Contact", link: "/contact" },
];

const FooterList = () => {
  return (
    <ul>
      {footerItems.map((item, i) => (
        <li key={i}>
          <Link to={item.link}>{item.itemName}</Link>
        </li>
      ))}
    </ul>
  );
};

export default FooterList;

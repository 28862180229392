import React from "react";
import FooterList from "../list/FooterList";

const FooterThreeDark = () => {
  return (
    <div className="row">
      <div className="col-12 col-lg">
        {/* <!--Animated Block--> */}
        <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
          <a href="/">
            <img
                src={`${window.location.protocol}//${window.location.host}/assets/img/root/logo-white.png`}
                alt="" loading="lazy" />
          </a>
        </div>
      </div>
      {/* End .col */}

      <div className="col-12 col-lg">
        {/* <!--Animated Block--> */}
        <div
          className="ptf-animated-block"
          data-aos="fade"
          data-aos-delay="100"
        >
          <div className="ptf-widget ptf-widget-links has-white-color">
            <FooterList />
          </div>
        </div>
      </div>
      {/* End .col */}

      <div className="col-12 col-lg">
        {/* <!--Animated Block--> */}
        <div
          className="ptf-animated-block"
          data-aos="fade"
          data-aos-delay="200"
        >
          <div className="ptf-widget ptf-widget-text">
            <a className="fz-36 has-white-color" href="mailto:contact@mostar.co.id">
              contact@mostar.co.id
            </a>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "0.625rem" }}
            ></div>
            <a className="fz-18 has-3-color" href="https://wa.me/6285314239877/" target="_blank">
              {/*Jl. Gunung Batu Dalam no. 5 — Cimahi*/}
              +62 853 1423 9877 <br/>
            </a>
          </div>
        </div>
      </div>
      {/* End .col */}
    </div>
  );
};

export default FooterThreeDark;

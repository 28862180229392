import React from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import {isWebpSupported} from 'react-image-webp/dist/utils';

const ImageGridFour = () => {
  return (
    <>
      <SimpleReactLightbox>
        <SRLWrapper>
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay="0"
          >
            {/* <!--Simple Image--> */}
            <div className="ptf-simple-image">
              <a
                href={`${window.location.protocol}//${window.location.host}/assets/img/service-detail.${isWebpSupported()  ? "webp" : "png"}`}
                data-fancybox
                rel="nofollow"
              >
                <img
                    src={`${window.location.protocol}//${window.location.host}/assets/img/service-detail.${isWebpSupported()  ? "webp" : "png"}`}
                  alt=""
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </SRLWrapper>
      </SimpleReactLightbox>
    </>
  );
};

export default ImageGridFour;

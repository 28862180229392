import React from "react";

const testimonialContent = {
  descriptions: `“Thank you for making my first experience of making a website a good experience`,
  name: "Alvin Leonardo",
  designation: "Director of PT Hakedo Putra Mandiri",
};

const TestimonialFour = () => {
  return (
    <div
      className="ptf-testimonial ptf-testimonial--style-3"
      style={{
        "--ptf-content-color": "var(--ptf-color-white)",
        "--ptf-author-color": "var(--ptf-color-white)",
        "--ptf-text-align": "left",
      }}
    >
      <div className="ptf-testimonial__content">
        <p>{testimonialContent.descriptions}</p>
      </div>
      <div className="ptf-testimonial__meta">
        <h6 className="ptf-testimonial__author">{testimonialContent.name}</h6>
        <div className="ptf-testimonial__info">
            <a href="https://hakedoputramandiri.com" target="_blank" style={{color:"white"}}>{testimonialContent.designation}</a>
        </div>
      </div>
    </div>
  );
};

export default TestimonialFour;

import React from "react";
import {isWebpSupported} from 'react-image-webp/dist/utils';

const brandContent = [
  {
    delayAnimation: "0",
    hoverBg: "#fcf8f4",
    imgHeight: "85px",
    imgName: "1-thatline",
    title: "Thatline",
    path: "https://thatlineproject.com/"
  },
  {
    delayAnimation: "100",
    hoverBg: "#f3f7fc",
    imgHeight: "78px",
    imgName: "2-acom",
    title: "Acom",
    path: "https://www.acom.co.id/"
  },
  {
    delayAnimation: "200",
    hoverBg: "#f9fcf3",
    imgHeight: "auto",
    imgName: "8-mekari",
    title: "Mekari",
    path: "https://mekari.com/produk"
  },
  {
    delayAnimation: "300",
    hoverBg: "#f9f9f9",
    imgHeight: "90px",
    imgName: "4-niagahoster",
    title: "Niagahoster",
    path: "https://www.niagahoster.co.id/"
  },
  {
    delayAnimation: "400",
    hoverBg: "#fdf4fb",
    imgHeight: "62px",
    imgName: "5-midtrans",
    title: "Midtrans",
    path: "https://midtrans.com/id"
  },
  {
    delayAnimation: "500",
    hoverBg: "#fdf4fb",
    imgHeight: "85px",
    imgName: "7-blink",
    title: "Blink",
    path: "https://blink.co.id/"
  },
];

const Brand = () => {
  return (
    <div className="row" style={{ "--bs-gutter-y": "2rem" }}>
      {brandContent.map((item, i) => (
        <div className="col-6 col-md-3 col-lg-2" key={i}>
          {/* <!--Animated Block--> */}
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay={item.delayAnimation}
          >
            {/* <!--Partner Box--> */}
            <div
              className="ptf-partner-box"
              style={{
                "--ptf-hover-background": item.hoverBg,
                "--ptf-image-height": item.imgHeight,
              }}
            >
              <a href={item.path} className="ptf-partner-box__image" target="_blank">
                <img
                  src={`assets/img/root/partners/${item.imgName}.${isWebpSupported()  ? "webp" : "png"}`}
                  alt="Partner"
                  loading="lazy"
                  target="_blank"
                />
              </a>
              <h6 className="ptf-partner-box__title">{item.title}</h6>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Brand;

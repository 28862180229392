import React from "react";
import { Link } from "react-router-dom";

const serviceContent = [
  {
    icon: "lnil lnil-Website",
    titlePart1: "WEBSITE",
    titlePart2: "DEVELOPMENT",
    descriptions: `Website responsif, design estetik dan profesional bagi Anda` ,
    pathString:'website'
  },
  {
    icon: "lnil lnil-mobile-alt-2",
    titlePart1: "MOBILE",
    titlePart2: "APPS",
    descriptions: `iOS dan Android Apps yang stabil, user-friendly dan easy-to-use`,
    pathString: 'mobile'
  },
  {
    icon: "lnil lnil-report-laptop",
    titlePart1: "ENTERPRISE",
    titlePart2: "SYSTEM",
    descriptions: `Tingkatkan produktifitas dengan sistem yang custom made, mudah digunakan dan scalable`,
    pathString: 'system'
  },
  // {
  //   icon: "lnil lnil-seo",
  //   titlePart1: "SEO",
  //   titlePart2: "",
  //   descriptions: `Tingkatkan visibility, ranking, dan traffic website Anda`,
  // pathString: 'seo'
  // },
  {
    icon: "lnil lnil-brush-alt",
    titlePart1: "UI/UX",
    titlePart2: "DESIGN",
    descriptions: `Tampilan menarik, nyaman dan mudah digunakan`,
    pathString: 'design'
  },
  // {
  //   icon: "lnil lnil-rocket",
  //   titlePart1: "SOCIAL MEDIA",
  //   titlePart2: "MANAGEMENT",
  //   descriptions: `Makin Eksis di SosMed dengan konsep dan konten menarik`,
  //   pathString: 'socialmedia'
  // },
  {
    icon: "lnil lnil-bullhorn",
    titlePart1: "DIGITAL",
    titlePart2: "MARKETING",
    descriptions: `Iklan yang tepat sasaran dan dapatkan leads berkualitas`,
    pathString: 'digitalmarketing'
  },
];

const ServiceOne = () => {
  return (
    <>
      {serviceContent.map((val, i) => (
        <div
          className="ptf-animated-block"
          data-aos="fade"
          data-aos-delay="0"
          key={i}
        >
          {/* <!--Services Box--> */}
          <div className="ptf-service-box">
            <Link
              to={'/service-details/' + val.pathString }
              className="ptf-service-box__link"
            ></Link>
            <div className="ptf-service-box__icon">
              <i className={val.icon}></i>
            </div>
            <h5 className="ptf-service-box__title">
              {val.titlePart1} <br />
              {val.titlePart2}
            </h5>
            <div className="ptf-service-box__content">
              <p>{val.descriptions}</p>
            </div>
            <div className="ptf-service-box__arrow">
              <i className="lnil lnil-chevron-right"></i>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ServiceOne;

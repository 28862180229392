import React, { useEffect } from "react";
import Routes from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
// import { jarallax } from "jarallax";
import AnimatedCursor from "react-animated-cursor";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Mostar Studio</title>
        <meta name="description" content="Software House Bandung | Jasa Desain Website Bandung | Web Agency | Jasa Software | Mostar adalah perusahaan IT (software house) yang melayani perangkat lunak dan desain. Didirikan sejak 2017. Mostar terutama berfokus pada beberapa produk yang meliputi: situs website, aplikasi seluler, konsultasi TI. Ini memiliki visi untuk menjadi perusahaan yang mendukung peremajaan proses bisnis perusahaan di Indonesia di seluruh perkembangan TI, dan misi untuk menciptakan produk yang membuat klien benar-benar merasa nyaman dengan implementasinya." />
        <meta
          name="keywords"
          content="Software House Bandung, Jasa Desain Website Bandung, Web Agency, Jasa Software"
        />
      </Helmet>
      {/* End React Helmet for SEO */}

      <AnimatedCursor
        innerSize={8}
        outerSize={44}
        color="220, 53, 69"
        outerAlpha={0.3}
        innerScale={0.7}
        outerScale={1.2}
      />
      {/* End Animated Cursor */}

      <ScrollToTop />
      {/* End Scroll To Top */}

      <Routes />
      {/* End All Routes */}
    </>
  );
};

export default App;

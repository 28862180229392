import React from "react";

const serviceContent = [
  { icon: "lnil-Website", name: "Website Development", path:"/service-details/website" },
  { icon: "lnil-mobile-alt-2", name: "Mobile Apps", path:"/service-details/mobile" },
  { icon: "lnil-report-laptop", name: "Enterprise System", path:"/service-details/system" },
  // { icon: "lnil-seo", name: "SEO" },
  { icon: "lnil-brush-alt", name: "UI/UX Design", path:"/service-details/design" },
  // { icon: "lnil-rocket", name: "Social Media Management" },
  { icon: "lnil-bullhorn", name: "Digital Marketing", path:"/service-details/digitalmarketing" },
];

const ServiceListThree = () => {
  return (
    <>
      <ul>
        {serviceContent.map((val, i) => (
          <li key={i}>
            <a href={`${val.path}`}>
              <i className={`lnil ${val.icon}`}></i> {val.name}
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default ServiceListThree;

import React from "react";

const approachContent = [
  {
    title: "Listen and Identify",
    descriptions: `Memahami kebutuhan, ekspektasi, dan tujuan Anda.`,
    delayAnimation: "0",
  },
  {
    title: "Brainstorming",
    descriptions: `Menganalisa kebutuhan Anda dan memberikan solusi yang optimal.`,
    delayAnimation: "100",
  },
  {
    title: "Development",
    descriptions: `Proses development kami menggunakan Sprint, memberikan progress yang terukur dalam waktu yang singkat serta melibatkan Anda dalam setiap progressnya.`,
    delayAnimation: "100",
  },
  {
    title: "Launching",
    descriptions: `Memastikan proses Launching yang smooth dan sesuai dengan ekspektasi Anda.`,
    delayAnimation: "200",
  },
  {
    title: "Maintenance",
    descriptions: `Memastikan Website/Mobile Apps/Sistem Anda berjalan dengan baik setelah Launching.`,
    delayAnimation: "300",
  },
];

const Approach2 = () => {
  return (
    <ul className="ptf-process-steps">
      {approachContent.map((val, i) => (
        <li
          className="ptf-process-item"
          data-aos="fade"
          data-aos-delay={val.delayAnimation}
          key={i}
        >
          <h6 className="ptf-process-item__title">{val.title}</h6>
          <p className="ptf-process-item__text">{val.descriptions}</p>
        </li>
      ))}
    </ul>
  );
};

export default Approach2;

import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const ContactForm = () => {
  // for validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nama wajib diisi"),
    organization: Yup.string().required("Nama Perusahaan/Bisnis wajib diisi"),
    projectGoal: Yup.string().required("Silahkan tulis keinginan Anda."),
    email: Yup.string()
      .required("Email wajib diisi")
      .email("Silahkan masukkan alama email yang valid"),
    budget: Yup.string().required("Silahkan pilih budget Anda"),
    acceptTerms: Yup.bool().oneOf(
      [true],
      "Anda perlu menyetujui syarat dan ketentuan"
    ),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState: { errors }, reset } = useForm(formOptions);
  const onSubmitHandler = (data) => {
      // console.log("Message submited: " + JSON.stringify(data));
      // window.open("https://wa.me/6285314239877/?text="+JSON.stringify(data), '_blank').focus();
      let emailBodyBuilder = "";
      emailBodyBuilder +=
          "<h2 style=\"font-weight: 700;\">New contact form application</h2>" +
          "<table>" +
          " <tr>" +
          "     <th>Nama</th>" +
          "     <td>: "+data.name+"Z</td>" +
          " </tr>" +
          " <tr>" +
          "     <th>Email</th>" +
          "     <td>: "+data.email+"</td>" +
          " </tr>" +
          " <tr>" +
          "     <th>Perusahaan</th>" +
          "     <td>: "+data.organization+"</td>" +
          " </tr>" +
          " <tr>" +
          "     <th>Kebutuhan</th>" +
          "     <td>: "+data.projectGoal+"</td>" +
          " </tr>" +
          " <tr>" +
          "     <th>Budget</th>" +
          "     <td>: "+data.budget+"</td>" +
          " </tr>" +
          "</table>";

      let formData = new FormData();
      formData.append('recipient_name',"Mostar Studio");
      formData.append('recipient_email',"contact@mostar.co.id");
      formData.append('subject',"Contact Form on Mostar Website");
      formData.append('data', emailBodyBuilder);

      const fileInput = document.querySelector('#productReference') ;
      formData.append('filedata',fileInput.files[0], fileInput.files[0].name);
      // for (var pair of formData.entries()) {
      //     console.log(pair[0]+ ', ' + pair[1]);
      // }

      fetch('https://service.inside.mostar.co.id/basic/mail_with_attachment', {
          method: 'POST',
          mode: 'no-cors',
          // headers: {
          //     'Content-Type': 'multipart/form-data'
          // },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: formData,
      })
          .then(response => response.text())
          .then(result => console.log(result))
          .catch(error => console.log('error', error));

          // .then(res => res.json())
          // .then((resJSON) => {
          //     console.log(resJSON);
          //     return resJSON;
          //     // this.setState({ contacts: data })
          // })
          // .catch(console.log)
        alert('Kami akan segera menghubungi Anda.')
        reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} encType="multipart/form-data" className="contact_form">
      <div className="ptf-form-group">
        <label data-number="01">Nama Anda</label>
        <input
          type="text"
          name="name"
          {...register("name")}
          className={`${errors.name ? "is-invalid" : ""}`}
        />
        {errors.name && (
          <div className="invalid-feedback">{errors.name?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        <label data-number="02">Nama Perusahaan atau Bisnis Anda</label>
        <input
          type="text"
          name="organization"
          {...register("organization")}
          className={`${errors.organization ? "is-invalid" : ""}`}
        />
        {errors.organization && (
          <div className="invalid-feedback">{errors.organization?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        <label data-number="03">Alamat Email</label>
        <input
          name="email"
          type="text"
          {...register("email")}
          className={` ${errors.email ? "is-invalid" : ""}`}
        />
        {errors.email && (
          <div className="invalid-feedback">{errors.email?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        <label data-number="04">Ceritakan Kebutuhan Anda.</label>
        <textarea
          type="text"
          name="projectGoal"
          {...register("projectGoal")}
          className={`${errors.projectGoal ? "is-invalid" : ""}`}
        />
        {errors.projectGoal && (
          <div className="invalid-feedback">{errors.projectGoal?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        <label data-number="05">Budget Anda</label>
        <select
          name="budget"
          {...register("budget")}
          className={`${errors.budget ? "is-invalid" : ""}`}
        >
          <option value="5">Di bawah IDR 5 juta</option>
          <option value="5-10">IDR 5 - 10 juta</option>
          <option value="10-35">IDR 10 - 35 juta</option>
          <option value="35">Di atas 35 juta</option>
          <option value="NaN">Belum yakin</option>
        </select>
        {errors.budget && (
          <div className="invalid-feedback">{errors.budget?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

        <div className="ptf-form-group">
            <label data-number="06">Referensi Produk</label>
            <input
                type="file"
                id="productReference"
                name="productReference"
                {...register("productReference")}
                className={`${errors.productReference ? "is-invalid" : ""}`}
            />
            {errors.productReference && (
                <div className="invalid-feedback">{errors.productReference?.message}</div>
            )}
        </div>
        {/* End .ptf-form-group */}

      {/* <!--Spacer--> */}
      <div className="ptf-spacer" style={{ "--ptf-xxl": "2.5rem" }}></div>

      <div className="ptf-form-group agreement-checkbox ">
        <input
          name="acceptTerms"
          type="checkbox"
          id="acceptTerms"
          {...register("acceptTerms")}
          className={` ${errors.acceptTerms ? "is-invalid" : ""}`}
        />

        <label className="ptf-checkbox" htmlFor="acceptTerms">
          <span className="ptf-checkbox__checkmark"></span> Saya setuju memberikan informasi diatas untuk disimpan, dijaga, dan diolah oleh Mostar Studio
        </label>
        {errors.acceptTerms && (
          <div className="invalid-feedback">{errors.acceptTerms?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      {/* <!--Spacer--> */}
      <div className="ptf-spacer" style={{ "--ptf-xxl": "5.625rem" }}></div>

      <button className="ptf-submit-button" type="submit">
        Kirim
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 17 17"
        >
          <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />
        </svg>
      </button>
      {/* End .ptf-submit-button */}
    </form>
  );
};

export default ContactForm;

import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import FooterThreeDark from "../../../components/footer/FooterThreeDark";
import CopyRightThreeDark from "../../../components/footer/copyright/CopyRightThreeDark";
import ServiceListThree from "../../../components/list/ServiceListThree";
import Faq from "../../../components/faq/Faq";
import Skills from "../../../components/skill/Skills";
import Approach2 from "../../../components/service/Approach2";
import CounterThree from "../../../components/counter/CounterThree";
import ImageGridFour from "../../../components/image-grid/ImageGridFour";
import { useParams } from "react-router-dom";
import {isWebpSupported} from 'react-image-webp/dist/utils';
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";

const serviceContents = [];
serviceContents.website = {
  icon: "lnil lnil-Website",
  title: "Website",
  subTitle: "Setiap bisnis membutuhkan website",
  descriptionTop: "Kemunculan bisnis dalam dunia digital menjadi suatu keharusan di era ini. Faktanya Website merupakan salah satu tools penting untuk meningkatkan <b>kredibilitas</b> perusahaan, menjadi etalase untuk <b>brand</b> Anda, menjaring <b>leads</b>, serta hadirnya website akan <b>memudahkan</b> pelanggan Anda untuk menemukan informasi tentang Bisnis Anda. Tim Mostar Studio siap menjadi partner Anda dalam pembuatan Landing Page, Website Company Profile, Website Catalog, Microsites dan E-Commerce.",
  descriptionBottom: "Mostar Studio menyediakan Website yang user-friendly dengan Content Management System (CMS) Anda dapat dengan mudah merubah dan meng-update konten di Website Anda",
  imgUrl: "/assets/img/service-detail-web"
}
serviceContents.mobile = {
  icon: "lnil lnil-mobile-alt-2",
  title: "Mobile Apps",
  subTitle: "",
  descriptionTop: "Menghadirkan mobile apps membawa bisnis berada satu langkah lebih maju. Mobile apps membantu membangun komunikasi langsung dengan pelanggan, membangun customer base, meningkatkan brand awareness, menciptakan loyalitas pelanggan dan menjadi sarana marketing yang efektif.",
  descriptionBottom: "Di Mostar Studio, tim kami berpengalaman dalam mengembangkan Aplikasi iOS dan Android untuk menjawab kebutuhan proses bisnis internal dan eksternal Anda.",
  imgUrl: "/assets/img/service-detail-mobile"
}
serviceContents.system = {
  icon: "lnil lnil-report-laptop",
  title: "Enterprise System",
  subTitle: "",
  descriptionTop: "Optimalkan efisiensi dan produktivitas bisnis Anda dengan solusi Enterprise System yang dikustomisasi dari Mostar Studio. Kami memahami bahwa setiap bisnis memiliki kebutuhan unik, itulah mengapa tim kami berdedikasi untuk menghadirkan solusi yang sesuai dengan tuntutan khusus dan skala operasional perusahaan Anda.",
  descriptionBottom: "Di Mostar Studio, tim kami berpengalaman dalam mengembangkan sistem kustom untuk menjawab kebutuhan proses bisnis internal Anda.",
  imgUrl: "/assets/img/service-detail-system"
}
serviceContents.seo = {
  icon: "lnil lnil-seo",
  title: "SEO",
  subTitle: "",
  descriptionTop: "Tanpa perlu diragukan lagi Search Engine Optimization (SEO) merupakan strategi marketing jangka panjang terbaik untuk menjaga eksistensi Website di dunia digital. Algoritma SEO yang selalu berkembang membuat Anda harus proaktif menjaga Website tetap relevan dengan trend.",
  descriptionBottom: "Di Mostar Studio, kami memiliki tim khusus SEO yang siap membantu Anda untuk memastikan eksistensi dan meningkatkan performa website Anda.",
  imgUrl: "/assets/img/service-detail-seo"
}
serviceContents.design = {
  icon: "lnil lnil-brush-alt",
  title: "UI/UX Design",
  subTitle: "",
  descriptionTop: "Mostar Studio terdiri dari tim UI/UX yang kreatif dan inovatif siap membantu menjembatani preferensi pengguna website atau mobile apps dengan kebutuhan Anda hingga tercipta sistem yang user-friendly, easy-to-use, estetik, dan representatif.",
  descriptionBottom: "Miliki feeds yang terkonsep serta up-to-date dan postings menarik dengan Tim Mostar Studio. Nikmati peningkatan engagement rate pada akun Sosial Media Anda.",
  imgUrl: "/assets/img/service-detail-uiux"
}
serviceContents.socialmedia = {
  icon: "lnil lnil-rocket",
  title: "Social Media Management",
  subTitle: "",
  descriptionTop: "Tau gak sih jumlah pengguna aktif sosial media di Indonesia saat ini sudah mencapai angka 60% dari total populasi di Indonesia. Sosial Media sebagai media komunikasi dua arah antara Brand dengan pelanggan yang sangat efektif menjaring dan menarik para calon pelanggan. Menaruh perhatian dan fokus untuk mengelola konten di SosMed sudah menjadi keharusan melihat besarnya potensi market dan impact yang ditimbulkan dari SosMed. ",
  descriptionBottom: "Memiliki feeds yang terkonsep serta up-to-date dan postings yang menarik dengan Tim Mostar Studio. Dan nikmati peningkatan engagement rate pada akun Sosial Media Anda.",
  imgUrl: "/assets/img/service-detail-smm"
}
serviceContents.digitalmarketing = {
  icon: "lnil lnil-bullhorn",
  title: "Digital Marketing",
  subTitle: "",
  descriptionTop: "Transformasikan bisnis Anda ke tingkat selanjutnya dengan layanan Digital Marketing dari Mostar Studio. Kami tidak hanya menciptakan kehadiran online yang tangguh melalui pembuatan website dan aplikasi mobile, tetapi juga mengoptimalkan strategi pemasaran digital untuk meningkatkan visibilitas dan daya tarik bisnis Anda.",
  descriptionBottom: "Raih calon pelanggan berkualitas melalui strategi marketing yang tepat sasaran dan di platform yang sesuai dengan jenis bisnis Anda.",
  imgUrl: "/assets/img/service-detail-digitalmarketing"
}

const ServiceDetails = () => {
  const {pathString} = useParams();

  const val = serviceContents[pathString];
  return (
    <>
    <div>
      <Helmet>
        <title>{val.title} - Mostar Studio</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}
      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">
                      {val.title}.
                    </h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18"
                       dangerouslySetInnerHTML={ {__html: val.descriptionTop } } >
                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
                  ></div>
                </div>
                <div className="col-lg-12">
                  {/* <!--Animated Block--> */}
                  {/*<ImageGridFour />*/}
                  <SimpleReactLightbox>
                    <SRLWrapper>
                      <div
                          className="ptf-animated-block"
                          data-aos="fade"
                          data-aos-delay="0"
                      >
                        {/* <!--Simple Image--> */}
                        <div className="ptf-simple-image">
                          <a
                              href={`${window.location.protocol}//${window.location.host}${val.imgUrl}.${isWebpSupported()  ? "webp" : "jpg"}`}
                              data-fancybox
                              rel="nofollow"
                          >
                            <img
                                src={`${window.location.protocol}//${window.location.host}${val.imgUrl}.${isWebpSupported()  ? "webp" : "jpg"}`}
                                alt=""
                                loading="lazy"
                            />
                          </a>
                        </div>
                      </div>
                    </SRLWrapper>
                  </SimpleReactLightbox>
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-8">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <p className="fz-18">
                      {val.descriptionBottom}
                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "4.375rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block d-none"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h3>Specialization</h3>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>
                    <p className="fz-18">
                      Encouraging the use of natural systems and processes in
                      design allows for exposure to nature, and in turn, these
                      design approaches improve health and wellbeing.
                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer  d-none"
                    style={{ "--ptf-xxl": "3.125rem" }}
                  ></div>
                  <div
                    className="row  d-none"
                    style={{
                      "--bs-gutter-x": "3.75rem",
                      "--bs-gutter-y": "7.5rem",
                    }}
                  >
                    {/*<CounterThree />*/}
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer d-none"
                    style={{ "--ptf-xxl": "6.875rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h3>Our Skills</h3>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "3.75rem" }}
                  ></div>
                  <div className="ptf-skill-box-grid">
                    <Skills />
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.875rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h3>How Do We Work</h3>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "5.625rem" }}
                  ></div>
                  {/* <!--Process Steps--> */}
                  <Approach2 />
                </div>
                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-services-list">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">Services List</h4>
                        <ServiceListThree />
                      </div>
                    </div>
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq d-none">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">FAQs</h4>
                        <Faq />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-5">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <FooterThreeDark />
          </div>
          {/* End .ptf-footer__top */}

          <div className="ptf-footer__bottom">
            <CopyRightThreeDark />
          </div>
          {/* End .ptf-footer__bottom */}
        </div>
      </footer>
    </div>
     {/* ))} */}
    </>
  );
};

export default ServiceDetails;
